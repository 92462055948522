/*             Navigation Pills               */

.nav-pills {
    .nav-item + .nav-link {
        margin-left: 0;
    }
    .nav-item .nav-link {
        border: 1px solid $default-color;
        border-radius: 0;
        color: $default-color;
        font-weight: $font-weight-bold;
        margin-left: -1px;
        padding: 10px 25px;

    }
    .nav-item.active .nav-link,
    .nav-item.active .nav-link:hover,
    .nav-item.active .nav-link:focus {
        background-color: $default-color;
        color: #FFFFFF;
    }
    .nav-item:first-child .nav-link{
        border-radius: 30px 0 0 30px !important;
        margin: 0;
    }
    .nav-item:last-child .nav-link{
        border-radius: 0 30px 30px 0 !important;
    }
    .nav-item .nav-link.active{
        background-color: $default-color;
        color: $white-color;
    }

}
.nav-pills-primary{
    .nav-item{
        .nav-link{
            border: 1px solid $primary-color;
            color: $primary-color;
            &.active{
                border: 1px solid $primary-color;
                background: $primary-color;
                color: $white-color;
            }
        }
    }
}
.nav-pills-danger{
    .nav-item{
        .nav-link{
            border: 1px solid $danger-color;
            color: $danger-color;
            &.active{
                border: 1px solid $danger-color;
                background: $danger-color;
                color: $white-color;
            }
        }
    }
}
.nav-pills-info{
    .nav-item{
        .nav-link{
            border: 1px solid $info-color;
            color: $info-color;
            &.active{
                border: 1px solid $info-color;
                background: $info-color;
                color: $white-color;
            }
        }
    }
}
.nav-pills-success{
    .nav-item{
        .nav-link{
            border: 1px solid $success-color;
            color: $success-color;
            &.active{
                border: 1px solid $success-color;
                background: $success-color;
                color: $white-color;
            }
        }
    }
}
.nav-pills-warning{
    .nav-item{
        .nav-link{
            border: 1px solid $warning-color;
            color: $warning-color;
            &.active{
                border: 1px solid $warning-color;
                background: $warning-color;
                color: $white-color;
            }
        }
    }
}




.page-item:first-child .page-link,
.page-item:last-child .page-link{
    border-bottom-left-radius: 20px;
	border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}
.nav-pills-default .nav-item.show .nav-link,
.nav-pills-default .nav-link.active{
    background-color: $default-color !important;
}
.nav-pills-primary .nav-item.show .nav-link,
.nav-pills-primary .nav-link.active{
    background-color: $primary-color !important;
}
.nav-pills-info .nav-item.show .nav-link,
.nav-pills-info .nav-link.active{
    background-color: $info-color !important;
}
.nav-pills-warning .nav-item.show .nav-link,
.nav-pills-warning .nav-link.active{
    background-color: $warning-color !important;
}
.nav-pills-success .nav-item.show .nav-link,
.nav-pills-success .nav-link.active{
    background-color: $success-color !important;
}
.nav-pills-danger .nav-item.show .nav-link,
.nav-pills-danger .nav-link.active{
    background-color: $danger-color !important;
}
