/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.rdtTimeToggle {
    font-weight: bold;
}

.sidebar-normal {
    text-transform: none !important;
}

.main-panel > .content {
    min-height: calc(100vh - 93px);
    margin-top: 93px;
}

@media only screen and (min-width: 478px) {
    .sweet-alert {
        width: 478px !important;
        margin-left: -239px !important;
    }
}

 .modal-dialog {
    max-width: 90%;
    min-width: 500px;
    width: fit-content;
    text-align: left;
}