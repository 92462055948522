.btn,
.navbar .navbar-nav > a.btn{
  i {
    margin-right: 3px;
  }
}
.table not(.btn-group) .btn + .btn,
.table .btn-group + .btn-group{
  margin-left: 4px;
}
.btn + .btn,
.btn-group + .btn-group {
  margin-left: 5px;
}
